// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-js": () => import("./../../../src/pages/Main.js" /* webpackChunkName: "component---src-pages-main-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-page-template-page-js": () => import("./../../../src/templates/page-template/page.js" /* webpackChunkName: "component---src-templates-page-template-page-js" */),
  "component---src-templates-post-category-template-category-js": () => import("./../../../src/templates/post-category-template/category.js" /* webpackChunkName: "component---src-templates-post-category-template-category-js" */),
  "component---src-templates-post-category-template-tag-template-js": () => import("./../../../src/templates/post-category-template/tagTemplate.js" /* webpackChunkName: "component---src-templates-post-category-template-tag-template-js" */),
  "component---src-templates-post-template-post-js": () => import("./../../../src/templates/post-template/post.js" /* webpackChunkName: "component---src-templates-post-template-post-js" */)
}

